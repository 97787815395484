<template>
    <div class="rule">
        <div class="rule-center">
            <el-container class="ruleContainer">
                <el-aside width="216px">
                    <div class="aside-list">
                        <div
                            class="aside-item"
                            :class="{ active: active === index }"
                            v-for="(item, index) in rules"
                            :key="index"
                            @click="getRule(item.chName)"
                        >
                            {{ $t(item.name) }}
                        </div>
                    </div>
                </el-aside>
                <el-container direction="vertical">
                    <el-main class="main scrollbar" v-loading="loading" ref="main">
                        <el-breadcrumb separator="/">
                            <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                            <el-breadcrumb-item>{{ ruleInfo ? $t(ruleInfo.name) : '' }}</el-breadcrumb-item>
                        </el-breadcrumb>
                        <div class="title">{{ ruleInfo ? $t(ruleInfo.name) : '' }}</div>
                        <div class="rule-content detail" v-if="ruleInfo">
                            <div v-html="ruleContentInfo.enName" v-if="!isChinese"></div>
                            <div v-if="!isChinese && ruleContentInfo.enName" class="rule-content-title">
                                {{ $i18n.messages.zh[ruleInfo.name] }}
                            </div>
                            <div v-html="ruleContentInfo.chName"></div>
                        </div>
                    </el-main>
                </el-container>
            </el-container>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Rule',
    data() {
        return {
            rules: [
                {
                    name: 'yong-hu-zhu-ce-xie-yi',
                    chName: 'RULE_REGISTER',
                    enName: 'RULE_REGISTER_EN'
                },
                {
                    name: 'yin-si-zheng-ce',
                    chName: 'RULE_PRIVACY',
                    enName: 'RULE_PRIVACY_EN'
                },
                {
                    name: 'ru-zhu-xu-zhi',
                    chName: 'RULE_ENTER',
                    enName: 'RULE_ENTER_EN'
                }
            ],
            type: 'RULE_REGISTER',
            mainInfo: '',
            loading: false,
            ruleContentInfo: {
                chName: '',
                enName: ''
            }
        };
    },
    computed: {
        ruleInfo() {
            return [...this.rules].find(item => {
                return item.chName === this.type;
            });
        },
        active() {
            return [...this.rules].findIndex(item => {
                return item.chName === this.type;
            });
        }
    },
    mounted() {
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        this.getRule(this.type);
    },
    methods: {
        getRule(type) {
            this.type = type;
            console.log(this.$refs.main.$el);
            this.$refs.main.$el.scrollTo({
                top: 0
            });
            this.$nextTick(() => {
                if (this.ruleInfo) {
                    this.loading = true;

                    this.$http.get(`/sysConfig/get/${this.ruleInfo.chName}`).then(res => {
                        this.ruleContentInfo.chName = res.value;
                        this.loading = false;
                    });
                    this.$http.get(`/sysConfig/get/${this.ruleInfo.enName}`).then(res => {
                        this.ruleContentInfo.enName = res.value;
                        this.loading = false;
                    });
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../styles/variables';
.rule-center {
    @include center-content(360);

    padding-top: 30px;
    padding-bottom: 60px;
    // box-sizing: border-box;
}

.ruleContainer {
    // min-height: calc(100vh - 538px);
    @include max-height(538px, $max: 1000px, $type: 'height');
    .el-aside {
        margin-right: 30px;

        .aside-item {
            width: 216px;
            height: 80px;

            font-size: 16px;
            font-weight: bold;
            color: #818999;
            line-height: 80px;
            text-align: center;
            background: #fff;
            cursor: pointer;

            &.active {
                font-size: 16px;
                font-weight: bold;
                color: #ffa526;
                background: #292c33;
            }
        }
    }

    .main {
        background-color: #fff;
        padding: 0 50px 30px;
        position: relative;

        .el-breadcrumb {
            background-color: #fff;
            padding: 30px 0;
            position: sticky;
            top: -1px;
            left: 0;
            right: 0;
        }

        .title {
            padding: 30px 0;
            // border-bottom: 1px solid #f2f3f5;
            font-size: 24px;
            font-weight: bold;
            color: #000000;
            line-height: 33px;
            text-align: center;
        }

        // .detail {
        //     padding: 30px 0 0;
        // }
    }
}
</style>

<style lang="scss">
.isEn {
    .rule {
        .detail {
            p {
                text-indent: 0em;
            }
        }
    }
}

.rule {
    .detail {
        p {
            text-indent: 2em;
        }
    }
}
</style>
